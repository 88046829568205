.headNavbar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    /* height: 100vh; */
    /* background-color: #ffcd51; */
    background-image: url('../../../assets/navBackground3.jpg');
    /* background-repeat: no-repeat; */
    /* background-size: cover; */
    padding: 20px 70px;
}

.title {
    font-family: 'Tilt Neon';
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    font-size: 28px;
    color: white;
}


/* List Menu Link */
.headListNavbar {
    width: 100%;
    /* background-color: #ffedc2; */
    /* background-color: #ffb73f; */
    background:url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='100%25' width='100%25'%3E%3Cdefs%3E%3Cpattern id='doodad' width='13' height='13' viewBox='0 0 40 40' patternUnits='userSpaceOnUse' patternTransform='rotate(135)'%3E%3Crect width='100%25' height='100%25' fill='rgba(255, 183, 63,1)'/%3E%3Ccircle cx='20' cy='20' r='2' fill='rgba(250, 245, 255,1)'/%3E%3Ccircle cx='30' cy='20' r='1' fill='rgba(255, 183, 63,1)'/%3E%3Ccircle cx='15' cy='28.66' r='1' fill='rgba(255, 183, 63,1)'/%3E%3Ccircle cx='15' cy='11.34' r='1' fill='rgba(255, 183, 63,1)'/%3E%3Ccircle cx='30' cy='20' r='1' fill='rgba(255, 183, 63,1)'/%3E%3C/pattern%3E%3C/defs%3E%3Crect fill='url(%23doodad)' height='200%25' width='200%25'/%3E%3C/svg%3E ");
    /* background-repeat: repeat; */
    /* background-image: url('../../../assets/patternav.jpg'); */
    padding: 10px 20px;
}

.bodyList {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 20px;
}

.list {
    color: 	#000;
    font-family: 'Tilt Neon';
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    font-size: 14px;
    text-decoration: none;
    /* text-shadow: 1px 2px 1px #00c2c7; */
}

.list:hover {
    font-weight: bolder;
    text-decoration: underline;
}

.Pembatas{
    color: 	#000;
}

