.backgroundContainer {
    /* background-color: #fefcbf; */
    background:url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='100%25' width='100%25'%3E%3Cdefs%3E%3Cpattern id='doodad' width='40' height='40' viewBox='0 0 40 40' patternUnits='userSpaceOnUse' patternTransform='rotate(135)'%3E%3Crect width='100%25' height='100%25' fill='rgba(42, 67, 101,1)'/%3E%3Cpath d='M-20 11l20-20l20 20l20-20l20 20l20-20l20 20v-2l-20-20l-20 20l-20-20l-20 20l-20-20l-20 20zM-20-29l20-20l20 20l20-20l20 20l20-20l20 20v-2l-20-20l-20 20l-20-20l-20 20l-20-20l-20 20zM-20 51l20-20l20 20l20-20l20 20l20-20l20 20v-2l-20-20l-20 20l-20-20l-20 20l-20-20l-20 20z ' fill='rgba(26, 32, 44,1)'/%3E%3Cpath d='M-20 30.5l20-20l20 20l20-20l20 20l20-20l20 20v-1l-20-20l-20 20l-20-20l-20 20l-20-20l-20 20zM-20-9.5l20-20l20 20l20-20l20 20l20-20l20 20v-1l-20-20l-20 20l-20-20l-20 20l-20-20l-20 20zM-20 70.5l20-20l20 20l20-20l20 20l20-20l20 20v-1l-20-20l-20 20l-20-20l-20 20l-20-20l-20 20z ' fill='rgba(236, 201, 75,1)'/%3E%3Cpath d='M-20 31l20-20l20 20l20-20l20 20l20-20l20 20v-2l-20-20l-20 20l-20-20l-20 20l-20-20l-20 20zM-20-9l20-20l20 20l20-20l20 20l20-20l20 20v-2l-20-20l-20 20l-20-20l-20 20l-20-20l-20 20zM-20 71l20-20l20 20l20-20l20 20l20-20l20 20v-2l-20-20l-20 20l-20-20l-20 20l-20-20l-20 20z ' fill='rgba(26, 32, 44,1)'/%3E%3C/pattern%3E%3C/defs%3E%3Crect fill='url(%23doodad)' height='200%25' width='200%25'/%3E%3C/svg%3E ");
    /* background:url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='100%25' width='100%25'%3E%3Cdefs%3E%3Cpattern id='doodad' width='272' height='272' viewBox='0 0 40 40' patternUnits='userSpaceOnUse' patternTransform=''%3E%3Crect width='100%25' height='100%25' fill='rgba(254, 252, 191,1)'/%3E%3Cpath d='M-20 37.5l20-20l20 20l20-20l20 20l20-20l20 20v-15l-20-20l-20 20l-20-20l-20 20l-20-20l-20 20zM-20-2.5l20-20l20 20l20-20l20 20l20-20l20 20v-15l-20-20l-20 20l-20-20l-20 20l-20-20l-20 20zM-20 77.5l20-20l20 20l20-20l20 20l20-20l20 20v-15l-20-20l-20 20l-20-20l-20 20l-20-20l-20 20z ' fill='rgba(44, 82, 130,1)'/%3E%3Cpath d='M24.5 0l20 20l-20 20l20 20l-20 20l20 20l-20 20h-9l20-20l-20-20l20-20l-20-20l20-20l-20-20zM-15.5 0l20 20l-20 20l20 20l-20 20l20 20l-20 20h-9l20-20l-20-20l20-20l-20-20l20-20l-20-20zM64.5 0l20 20l-20 20l20 20l-20 20l20 20l-20 20h-9l20-20l-20-20l20-20l-20-20l20-20l-20-20z ' fill='rgba(255, 183, 63,1)'/%3E%3C/pattern%3E%3C/defs%3E%3Crect fill='url(%23doodad)' height='200%25' width='200%25'/%3E%3C/svg%3E "); */
    /* background:url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='100%25' width='100%25'%3E%3Cdefs%3E%3Cpattern id='doodad' width='158' height='158' viewBox='0 0 40 40' patternUnits='userSpaceOnUse' patternTransform=''%3E%3Crect width='100%25' height='100%25' fill='rgba(254, 252, 191,1)'/%3E%3Cpath d='M-20 37.5l20-20l20 20l20-20l20 20l20-20l20 20v-15l-20-20l-20 20l-20-20l-20 20l-20-20l-20 20zM-20-2.5l20-20l20 20l20-20l20 20l20-20l20 20v-15l-20-20l-20 20l-20-20l-20 20l-20-20l-20 20zM-20 77.5l20-20l20 20l20-20l20 20l20-20l20 20v-15l-20-20l-20 20l-20-20l-20 20l-20-20l-20 20z ' fill='rgba(43, 108, 176,1)'/%3E%3Cpath d='M24.5 0l20 20l-20 20l20 20l-20 20l20 20l-20 20h-9l20-20l-20-20l20-20l-20-20l20-20l-20-20zM-15.5 0l20 20l-20 20l20 20l-20 20l20 20l-20 20h-9l20-20l-20-20l20-20l-20-20l20-20l-20-20zM64.5 0l20 20l-20 20l20 20l-20 20l20 20l-20 20h-9l20-20l-20-20l20-20l-20-20l20-20l-20-20z ' fill='rgba(255, 183, 63,1)'/%3E%3C/pattern%3E%3C/defs%3E%3Crect fill='url(%23doodad)' height='200%25' width='200%25'/%3E%3C/svg%3E "); */
    /* height: 100vh; */
}

.card__left{
    /* background-color: #434343; */
    /* background-color:#ffb73f; */
    background:url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='100%25' width='100%25'%3E%3Cdefs%3E%3Cpattern id='doodad' width='34.64' height='40' viewBox='0 0 69.2820323027551 80' patternUnits='userSpaceOnUse' patternTransform='rotate(135)'%3E%3Crect width='100%25' height='100%25' fill='rgba(255, 183, 63,1)'/%3E%3Cpath d='M52.83 50.5L51.96 51L51.09 50.5L51.09 49.5L51.96 49L52.83 49.5z' stroke-linejoin='miter' fill='rgba(26, 32, 44,1)'/%3E%3Cpath d='M35.51 60.5L34.64 61L33.77 60.5L33.77 59.5L34.64 59L35.51 59.5z' stroke-linejoin='miter' fill='rgba(26, 32, 44,1)'/%3E%3Cpath d='M18.19 50.5L17.32 51L16.45 50.5L16.45 49.5L17.32 49L18.19 49.5z' stroke-linejoin='miter' fill='rgba(26, 32, 44,1)'/%3E%3Cpath d='M18.19 30.5L17.32 31L16.45 30.5L16.45 29.5L17.32 29L18.19 29.5z' stroke-linejoin='miter' fill='rgba(26, 32, 44,1)'/%3E%3Cpath d='M35.51 20.5L34.64 21L33.77 20.5L33.77 19.5L34.64 19L35.51 19.5z' stroke-linejoin='miter' fill='rgba(26, 32, 44,1)'/%3E%3Cpath d='M52.83 30.5L51.96 31L51.09 30.5L51.09 29.5L51.96 29L52.83 29.5z' stroke-linejoin='miter' fill='rgba(26, 32, 44,1)'/%3E%3Cpath d='M36.37 41L34.64 42L32.91 41L32.91 39L34.64 38L36.37 39z' stroke-linejoin='miter' fill='rgba(236, 201, 75,1)'/%3E%3C/pattern%3E%3C/defs%3E%3Crect fill='url(%23doodad)' height='200%25' width='200%25'/%3E%3C/svg%3E ");
     /* background:url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='100%25' width='100%25'%3E%3Cdefs%3E%3Cpattern id='doodad' width='34.64' height='40' viewBox='0 0 69.2820323027551 80' patternUnits='userSpaceOnUse' patternTransform='rotate(135)'%3E%3Crect width='100%25' height='100%25' fill='rgba(246, 224, 94,1)'/%3E%3Cpath d='M52.83 50.5L51.96 51L51.09 50.5L51.09 49.5L51.96 49L52.83 49.5z' stroke-linejoin='miter' fill='rgba(26, 32, 44,1)'/%3E%3Cpath d='M35.51 60.5L34.64 61L33.77 60.5L33.77 59.5L34.64 59L35.51 59.5z' stroke-linejoin='miter' fill='rgba(26, 32, 44,1)'/%3E%3Cpath d='M18.19 50.5L17.32 51L16.45 50.5L16.45 49.5L17.32 49L18.19 49.5z' stroke-linejoin='miter' fill='rgba(26, 32, 44,1)'/%3E%3Cpath d='M18.19 30.5L17.32 31L16.45 30.5L16.45 29.5L17.32 29L18.19 29.5z' stroke-linejoin='miter' fill='rgba(26, 32, 44,1)'/%3E%3Cpath d='M35.51 20.5L34.64 21L33.77 20.5L33.77 19.5L34.64 19L35.51 19.5z' stroke-linejoin='miter' fill='rgba(26, 32, 44,1)'/%3E%3Cpath d='M52.83 30.5L51.96 31L51.09 30.5L51.09 29.5L51.96 29L52.83 29.5z' stroke-linejoin='miter' fill='rgba(26, 32, 44,1)'/%3E%3Cpath d='M36.37 41L34.64 42L32.91 41L32.91 39L34.64 38L36.37 39z' stroke-linejoin='miter' fill='rgba(236, 201, 75,1)'/%3E%3C/pattern%3E%3C/defs%3E%3Crect fill='url(%23doodad)' height='200%25' width='200%25'/%3E%3C/svg%3E "); */
    /* border: 2px solid black; */
    border-radius: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px 20px;
    /* box-shadow: 5px 5px 10px 2px #FFF; */
    box-shadow: 5px 5px 20px 2px #262626;
    /* border: 1px solid #262626; */
    transition: transform .3s;
}

.card__left:hover {
    transform:scale(1.02);
    transition: transform .2s;
}

.card__right{
    /* background-color: #434343; */
    /* background-color:#ffb73f; */
    background:url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='100%25' width='100%25'%3E%3Cdefs%3E%3Cpattern id='doodad' width='34.64' height='40' viewBox='0 0 69.2820323027551 80' patternUnits='userSpaceOnUse' patternTransform='rotate(135)'%3E%3Crect width='100%25' height='100%25' fill='rgba(255, 183, 63,1)'/%3E%3Cpath d='M52.83 50.5L51.96 51L51.09 50.5L51.09 49.5L51.96 49L52.83 49.5z' stroke-linejoin='miter' fill='rgba(26, 32, 44,1)'/%3E%3Cpath d='M35.51 60.5L34.64 61L33.77 60.5L33.77 59.5L34.64 59L35.51 59.5z' stroke-linejoin='miter' fill='rgba(26, 32, 44,1)'/%3E%3Cpath d='M18.19 50.5L17.32 51L16.45 50.5L16.45 49.5L17.32 49L18.19 49.5z' stroke-linejoin='miter' fill='rgba(26, 32, 44,1)'/%3E%3Cpath d='M18.19 30.5L17.32 31L16.45 30.5L16.45 29.5L17.32 29L18.19 29.5z' stroke-linejoin='miter' fill='rgba(26, 32, 44,1)'/%3E%3Cpath d='M35.51 20.5L34.64 21L33.77 20.5L33.77 19.5L34.64 19L35.51 19.5z' stroke-linejoin='miter' fill='rgba(26, 32, 44,1)'/%3E%3Cpath d='M52.83 30.5L51.96 31L51.09 30.5L51.09 29.5L51.96 29L52.83 29.5z' stroke-linejoin='miter' fill='rgba(26, 32, 44,1)'/%3E%3Cpath d='M36.37 41L34.64 42L32.91 41L32.91 39L34.64 38L36.37 39z' stroke-linejoin='miter' fill='rgba(236, 201, 75,1)'/%3E%3C/pattern%3E%3C/defs%3E%3Crect fill='url(%23doodad)' height='200%25' width='200%25'/%3E%3C/svg%3E ");
     /* background:url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='100%25' width='100%25'%3E%3Cdefs%3E%3Cpattern id='doodad' width='34.64' height='40' viewBox='0 0 69.2820323027551 80' patternUnits='userSpaceOnUse' patternTransform='rotate(135)'%3E%3Crect width='100%25' height='100%25' fill='rgba(246, 224, 94,1)'/%3E%3Cpath d='M52.83 50.5L51.96 51L51.09 50.5L51.09 49.5L51.96 49L52.83 49.5z' stroke-linejoin='miter' fill='rgba(26, 32, 44,1)'/%3E%3Cpath d='M35.51 60.5L34.64 61L33.77 60.5L33.77 59.5L34.64 59L35.51 59.5z' stroke-linejoin='miter' fill='rgba(26, 32, 44,1)'/%3E%3Cpath d='M18.19 50.5L17.32 51L16.45 50.5L16.45 49.5L17.32 49L18.19 49.5z' stroke-linejoin='miter' fill='rgba(26, 32, 44,1)'/%3E%3Cpath d='M18.19 30.5L17.32 31L16.45 30.5L16.45 29.5L17.32 29L18.19 29.5z' stroke-linejoin='miter' fill='rgba(26, 32, 44,1)'/%3E%3Cpath d='M35.51 20.5L34.64 21L33.77 20.5L33.77 19.5L34.64 19L35.51 19.5z' stroke-linejoin='miter' fill='rgba(26, 32, 44,1)'/%3E%3Cpath d='M52.83 30.5L51.96 31L51.09 30.5L51.09 29.5L51.96 29L52.83 29.5z' stroke-linejoin='miter' fill='rgba(26, 32, 44,1)'/%3E%3Cpath d='M36.37 41L34.64 42L32.91 41L32.91 39L34.64 38L36.37 39z' stroke-linejoin='miter' fill='rgba(236, 201, 75,1)'/%3E%3C/pattern%3E%3C/defs%3E%3Crect fill='url(%23doodad)' height='200%25' width='200%25'/%3E%3C/svg%3E "); */
    /* border: 2px solid black; */
    border-radius: 50px;
    display: flex;
    flex-direction: 'columns';
    align-items: center;
    justify-content: center;
    padding: 30px 20px;
    /* box-shadow: 5px 5px 10px 2px #FFF; */
    box-shadow: 5px 5px 20px 2px #262626;
    /* border: 1px solid #262626; */
    transition: transform .3s;
}

.card__right:hover {
    transform:scale(1.02);
    transition: transform .2s;
}

.paymentMethod {
    /* background-color: #f0f0f0; */
    background:url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='100%25' width='100%25'%3E%3Cdefs%3E%3Cpattern id='doodad' width='16' height='16' viewBox='0 0 40 40' patternUnits='userSpaceOnUse' patternTransform='rotate(135)'%3E%3Crect width='100%25' height='100%25' fill='rgba(240, 240, 240,1)'/%3E%3Ccircle cx='20' cy='20' r='2' fill='rgba(26, 32, 44,1)'/%3E%3Ccircle cx='20' cy='-20' r='2' fill='rgba(26, 32, 44,1)'/%3E%3Ccircle cx='0' cy='20' r='1' fill='rgba(236, 201, 75,1)'/%3E%3Ccircle cx='20' cy='0' r='1' fill='rgba(236, 201, 75,1)'/%3E%3Ccircle cx='40' cy='20' r='1' fill='rgba(236, 201, 75,1)'/%3E%3Ccircle cx='20' cy='40' r='1' fill='rgba(236, 201, 75,1)'/%3E%3C/pattern%3E%3C/defs%3E%3Crect fill='url(%23doodad)' height='200%25' width='200%25'/%3E%3C/svg%3E ");
    box-shadow: 5px 5px 20px 2px #262626;
    margin-top: 20px;
    padding: 40px;
    border-radius: 40px;
    transition: transform .3s;
    font-family: 'Tilt Neon';
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    /* font-size: 14px; */
}

.paymentMethod:hover {
    transform:scale(1.02);
    transition: transform .2s;   
}

.bankRegister{
    font-family: 'Tilt Neon';
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    font-size: 14px;
}